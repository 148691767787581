import React from "react"
import Layout from "../components/layout"
import Pager from '../components/pager';
import { Link, graphql } from 'gatsby'
import SEO from "../components/seo"
import styled from "@emotion/styled"

/* Blog Content Container */
const BlogContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 10rem;
`

/* Blog Header */
const BlogHeaderContainer = styled.div`
  margin-bottom: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
          align-items: center;
  width: 100%;  
`

const BlogTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
`

const BlogLine = styled.div`
  background-color: #7798ab;
  width: 1rem;
  margin: 0 auto;
  height: 0.1rem;
  margin-top: 0.5rem;
`

/* Blog Archive Looks */
const BlogArchiveContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  
  @media (max-width: 425px) {
    flex-direction: column;
  }
`
const BlogTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 2px solid black;
  margin: 1rem 1rem 0 1rem;

  img {
    height: 300px;
    width: 300px;
    object-fit: cover;
  }
`
const BlogLink = styled(props => <Link {...props} />)`
  text-decoration: none;
  color: #000000FF;
  cursor: pointer; 
`

const BlogArchiveTitle = styled.h3`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-style: italic;
  font-weight: bold;
  letter-spacing: 0.05rem;
  font-size: 1.5rem;
  -webkit-transition: background-color 0.5s ease;
  -o-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
  width: 250px;
  margin: 0 auto;
  padding: 1rem;
  
  @media (max-width: 320px) {
    line-height: 1.25rem; 
  }
`

const BlogArchiveDate = styled.p`
  font-weight: 400;
  margin: 0;
  margin-top: 0.5rem;
`


const BlogArchive = ({ data, pageContext, location }) => {
    const posts = data.allMarkdownRemark.edges;

    return (
      <Layout location={location}>
        <SEO title="Blog" />
        <BlogContentContainer>
          <BlogHeaderContainer>
            <BlogTitle>Blog</BlogTitle>
            <BlogLine>&nbsp;</BlogLine>
          </BlogHeaderContainer>
          <BlogArchiveContainer>
          {posts.map(({ node }) => {
              const title = node.frontmatter.title || node.fields.slug;
              const featuredImg = node.frontmatter.featuredImg;
              return (
                <BlogTitleContainer key={node.fields.slug}>
                  <BlogLink to={node.fields.slug}>
                  <BlogArchiveTitle>
                      {title}
                      <BlogArchiveDate>
                          {node.frontmatter.date}
                      </BlogArchiveDate>
                  </BlogArchiveTitle>
                  <img src={featuredImg} alt={title} style={{marginBottom: 0}} />
                  </BlogLink>
                </BlogTitleContainer>
              )
          })}
          </BlogArchiveContainer>
          <Pager pageContext={pageContext} />
        </BlogContentContainer>
      </Layout>
    )
};

export default BlogArchive;

export const pageQuery = graphql`
query blogListTemplateQuery($skip: Int!, $limit: Int!) {
  allMarkdownRemark(
    sort: { fields: [frontmatter___date], order: DESC }
    limit: $limit
    skip: $skip
    filter: {fileAbsolutePath: {regex: "content/blog/"}}) {
    edges {
      node {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          featuredImg
        }
      }
    }
  }
  site {
    siteMetadata {
      title
    }
  }
}

`