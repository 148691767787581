import React from 'react';
import { Link } from 'gatsby';
import styled from "@emotion/styled"
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";
import { IconContext } from "react-icons";

const BlogIndexNav = styled.nav`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  @media (max-width: 425px) {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
`

const BlogButtonContainer = styled.div`
  text-align: center;
  @media (max-width: 425px) {
    margin-bottom: 1.25rem;
  }
`

const BlogIndexButton = styled.button`
  border: none;
  color: #ededed;
  background-color: black;
  padding: 0.75rem;
  margin: 1rem;
  font-style: italic;
  font-weight: bold;
  letter-spacing: 0.05rem;
  font-size: 1.1rem;
  transition: background-color 0.5s ease;

  &:hover {
    background-color: #ededed;
    border: 1px solid black;
    color: black;
    transition: background-color 0.5s ease;
  }

  &:focus {
    outline: none;
  }
`

const Pager = ({ pageContext }) => {
  console.log(pageContext);
  const { previousPagePath, nextPagePath } = pageContext;
  return (
    <IconContext.Provider value={{ className: "blog-post-buttons" }}>
    <BlogIndexNav>
      <BlogButtonContainer>
        {previousPagePath && (
          <Link to={previousPagePath}>
            <BlogIndexButton><IoMdArrowBack />Newer Posts</BlogIndexButton>
          </Link>
        )}
      </BlogButtonContainer>

      <BlogButtonContainer style={{ justifySelf: 'flex-end' }}>
        {nextPagePath && (
          <Link to={nextPagePath}>
            <BlogIndexButton>Older Posts<IoMdArrowForward /></BlogIndexButton>
          </Link>
        )}
      </BlogButtonContainer>
    </BlogIndexNav>
    </IconContext.Provider>
  );
};

export default Pager;